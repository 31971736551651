import PersonalData from "./PersonalData";
import "./personal.css";

function PersonalInformation() {
  return (
    <div className="max_width">
      <PersonalData />
    </div>
  );
}
export default PersonalInformation;
