import React from "react";
import PropTypes from "prop-types";

function CheckBox({ color }) {
  return (
    <div>
      <svg
        width="16"
        height="17"
        viewBox="0 0 16 17"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M14.2228 16.3487H1.70673C0.764118 16.3487 0 15.5645 0 14.597V1.75164C0 0.784226 0.764118 0 1.70673 0H14.2228C15.1654 0 15.9295 0.784226 15.9295 1.75164V14.597C15.9295 15.5645 15.1654 16.3487 14.2228 16.3487ZM6.94476 12.7703L13.4872 6.05562C13.7094 5.82761 13.7094 5.45791 13.4872 5.2299L12.6827 4.40418C12.4605 4.17618 12.1003 4.17614 11.8781 4.40418L6.54247 9.88019L4.05139 7.32355C3.82923 7.09555 3.469 7.09555 3.24681 7.32355L2.44226 8.14927C2.2201 8.37728 2.2201 8.74698 2.44226 8.97499L6.14018 12.7702C6.36237 12.9983 6.72256 12.9983 6.94476 12.7703Z"
          fill={color}
        />
      </svg>
    </div>
  );
}

CheckBox.propstype = {
  color: PropTypes.string.isRequired,
};

export default CheckBox;
